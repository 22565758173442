import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import ReactPlayer from 'react-player';
import io from 'socket.io-client';

const socket = io('http://webdev.uz', {
    transports: ['websocket', 'polling'],
    cors: {
        origin: "*"
    }
});

const App = () => {
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [videoQuality, setVideoQuality] = useState('highest');
    const [isDownloading, setIsDownloading] = useState(false);
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [downloadLink, setDownloadLink] = useState(null);
    const [keyMoments, setKeyMoments] = useState([]);
    const [progress, setProgress] = useState(0);
    const [statusMessage, setStatusMessage] = useState('');
    const [fragmentLinks, setFragmentLinks] = useState({});
    const [connecting, setConnecting] = useState(false);
    const videoPlayerRef = useRef(null);
    const socketRef = useRef(null);

    useEffect(() => {
        socketRef.current = socket;
        setConnecting(true);

        socketRef.current.on('connect', () => {
            setConnecting(false);
        });

        socketRef.current.on('downloadProgress', ({ progress }) => {
            setProgress(progress);
            setStatusMessage(`Прогресс загрузки: ${progress}%`);
        });

        socketRef.current.on('analyzeProgress', ({ progress }) => {
            setProgress(progress);
            setStatusMessage(`Прогресс анализа: ${progress}%`);
        });

        socketRef.current.on('fragmentProgress', ({ progress }) => {
            setProgress(progress);
            setStatusMessage(`Прогресс нарезки: ${progress}%`);
        });

        return () => {
            socketRef.current.off('downloadProgress');
            socketRef.current.off('analyzeProgress')
            socketRef.current.off('fragmentProgress');
        };
    }, []);

    const handleUrlChange = (event) => {
        setYoutubeUrl(event.target.value);
    };

    const handleQualityChange = (event) => {
        setVideoQuality(event.target.value);
    };

    const getRequestConfig = (body) => {
        return {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ ...body, socketId: socketRef.current.id })
        }
    }

    const handleDownload = async () => {
        setIsDownloading(true);
        setProgress(0);
        setDownloadLink(null);

        try {
            const response = await fetch("http://webdev.uz/download", getRequestConfig({ youtubeUrl, videoQuality }));
            const blob = await response.blob();
            const downloadUrl = URL.createObjectURL(blob);
            setDownloadLink(downloadUrl);
            setProgress(100);
            setStatusMessage("Скачивание завершено.");
        } catch (error) {
            console.error('Ошибка при загрузке видео:', error);
        } finally {
            setIsDownloading(false);
        }
    };

    const handleAnalyze = async () => {
        setIsAnalyzing(true);
        setProgress(0);
        setKeyMoments([]);

        try {
            const response = await fetch("http://webdev.uz/analyze", getRequestConfig({ youtubeUrl, videoQuality }));
            const keyMoments = await response.json();
            const nonOverlappingKeyMoments = adjustOverlappingTimestamps(keyMoments);
            setKeyMoments(nonOverlappingKeyMoments);
            setProgress(100);
            setStatusMessage("Анализ завершен.");
        } catch (error) {
            console.error('Ошибка при анализе видео:', error);
        } finally {
            setIsAnalyzing(false);
        }
    };

    const adjustOverlappingTimestamps = (keyMoments) => {
        let lastTimestamp = 0;
        return keyMoments.map((moment) => {
            const timeParts = moment.timestamp.split(':').map(Number);
            const seconds = (timeParts[0] * 60 * 60) + (timeParts[1] * 60) + timeParts[2];
            if (seconds <= lastTimestamp) {
                moment.timestamp = secondsToTimestamp(lastTimestamp + 1);
            }
            lastTimestamp = seconds;
            return moment;
        });
    };

    const secondsToTimestamp = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    const handleKeyMomentClick = (timestamp) => {
        const timeParts = timestamp.split(':').map(Number);
        const seconds = (timeParts[0] * 60 * 60) + (timeParts[1] * 60) + timeParts[2];
        if (videoPlayerRef.current) {
            videoPlayerRef.current.seekTo(seconds, 'seconds');
        }
    };

    const handleFragmentDownload = async (timestamp) => {
        setIsDownloading(true);

        const durationMinutes = 1;
        try {
            const response = await fetch("http://webdev.uz/fragment", getRequestConfig({
                youtubeUrl,
                videoQuality,
                timestamp,
                durationMinutes
            }));

            const blob = await response.blob();
            const downloadUrl = URL.createObjectURL(blob);
            setFragmentLinks(prevState => ({ ...prevState, [timestamp]: downloadUrl }));
            setProgress(100);
            setStatusMessage("Фрагмент готов.");
        } catch (error) {
            console.error('Ошибка при нарезке видео:', error);
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <div className="container">
            <div className="sidebar left-sidebar">
                <h2>Реклама</h2>
                <p>Ваше рекламное объявление здесь</p>
            </div>

            <div className="content">
                <h1 className="title">YouTube Downloader with AI Analysis</h1>
                <div className="form">
                    <input
                        type="text"
                        value={youtubeUrl}
                        onChange={handleUrlChange}
                        placeholder="Вставьте ссылку на YouTube видео"
                        className="input"
                        disabled={connecting}
                    />
                    <div className="radio-group">
                        <div className="labels_wrap">
                            <label>
                                <input
                                    type="radio"
                                    value="highest"
                                    checked={videoQuality === 'highest'}
                                    onChange={handleQualityChange}
                                    className="radio-input"
                                />
                                Наивысшее качество
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="lowest"
                                    checked={videoQuality === 'lowest'}
                                    onChange={handleQualityChange}
                                    className="radio-input"
                                />
                                Низшее качество
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="360p"
                                    checked={videoQuality === '360p'}
                                    onChange={handleQualityChange}
                                    className="radio-input"
                                />
                                360p
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="720p"
                                    checked={videoQuality === '720p'}
                                    onChange={handleQualityChange}
                                    className="radio-input"
                                />
                                720p
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="1080p"
                                    checked={videoQuality === '1080p'}
                                    onChange={handleQualityChange}
                                    className="radio-input"
                                />
                                1080p
                            </label>
                        </div>
                        <div className="label_about">
                            <p>
                                В этом сервисе вы можете скачать видео с YouTube в различных качествах. Выберите нужное
                                качество из
                                предложенных вариантов. Доступные варианты качества включают:
                            </p>
                            <p>
                                Когда вы выберете качество, сервис скачает видео в выбранном формате. Затем вы сможете
                                анализировать
                                видео, просматривать ключевые моменты и загружать фрагменты.
                            </p>
                        </div>
                    </div>
                    <button
                        onClick={handleDownload}
                        className={`submit-btn ${isDownloading ? "loading" : ""}`}
                        disabled={isDownloading}
                    >
                        {isDownloading ? (
                            <div className="spinner"></div>
                        ) : "Скачать видео"}
                    </button>
                    <button
                        onClick={handleAnalyze}
                        className={`submit-btn ${isAnalyzing ? "loading" : ""}`}
                        disabled={isAnalyzing}
                    >
                        {isAnalyzing ? (
                            <div className="spinner"></div>
                        ) : "Анализировать видео"}
                    </button>
                </div>

                {(isDownloading || isAnalyzing) && (
                    <div className="progress">
                        <div>{statusMessage}</div>
                        Прогресс: {progress.toFixed(2)}%
                        <div className="progress-bar" style={{width: `${progress}%`}}></div>
                    </div>
                )}

                {downloadLink && (
                    <div className="video-player">
                        <h2>Видео</h2>
                        <ReactPlayer
                            ref={videoPlayerRef}
                            url={downloadLink}
                            controls
                            width="100%"
                        />
                        <a href={downloadLink} download className="download-link">
                            Скачать видео
                        </a>
                    </div>
                )}

                {keyMoments.length > 0 && (
                    <div className="key-moments">
                        <h2>Ключевые моменты</h2>
                        <table className="key-moments-table">
                            <thead>
                            <tr>
                                <th>Время</th>
                                <th>Описание</th>
                                <th>Действия</th>
                            </tr>
                            </thead>
                            <tbody>
                            {keyMoments.map((moment, index) => (
                                <tr key={index}>
                                    <td>{moment.timestamp}</td>
                                    <td>{moment.description}</td>
                                    <td>
                                        <button onClick={() => handleKeyMomentClick(moment.timestamp)}>
                                            Перейти
                                        </button>
                                        {fragmentLinks[moment.timestamp] ? (
                                            <a href={fragmentLinks[moment.timestamp]} download className="download-link">
                                                Скачать фрагмент
                                            </a>
                                        ) : (
                                            <button onClick={() => handleFragmentDownload(moment.timestamp)}>
                                                Скачать минутный фрагмент
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}

                <div className="seo-text">
                    <h2>SEO Текст</h2>
                    <p>
                        Этот сервис предоставляет удобный способ скачать и анализировать видео с YouTube. Используя
                        продвинутые алгоритмы анализа, вы можете извлекать ключевые моменты и нарезать фрагменты видео
                        для дальнейшего использования. Наша система позволяет скачивать видео в различных качествах,
                        включая 360p, 720p и 1080p. Убедитесь, что вы вставили корректную ссылку на видео и выбрали
                        нужное качество для загрузки. Попробуйте наш сервис для удобного и быстрого скачивания и анализа
                        видео.
                    </p>
                </div>
            </div>

            <div className="sidebar right-sidebar">
                <h2>Реклама</h2>
                <p>Ваше рекламное объявление здесь</p>
            </div>
        </div>
    );
};

export default App;